/* Root */
:root {
  --white: #4c545d;
  --fuchsia: rgba(255,255,255,0.6);
  --fuchsia2: rgba(255,255,255,0.8);
  --green: #65bc7b;
  --ebony-clay: #26303e;
}
/* Footer Content */

.title{
  font-size: 14px !important;
  line-height: 1.5 !important;
  margin-bottom: 20px !important;
  text-transform: uppercase;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  color: var(--white) !important;
}
.description, ul>li{
  font-size: 16px !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: 1.8 !important;
  color: var(--white)!important;
  padding: 10px 0 !important;
}
.footerContact span{
	color: var(--white) !important;
}
.menu > li {
  border-bottom: 1px solid var(--green);
}
.link{
  color: var(--white) !important;
  font-size: 16px !important;
  transition: 0.2s;
}
.link:hover{
  color: var(--green) !important;
}
.footerSocial > a{
  font-size: 16px;
  margin: 0 10px 10px;
  cursor: pointer;
  transition-duration: .2s;
  color: rgba(255,255,255,0.4) !important;
}
.footerSocial{
  display: inline-block;
  position: relative;
}

.footerSocial .tooltiptext {
  visibility: hidden;
  /* width: 120px; */
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  margin-bottom: 10px;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}
.footerSocial:hover .tooltiptext {
  visibility: visible;
}

@media only screen and (max-width: 768px){
  .footer{
    padding: 0 10px;
  }
}