/* Fonts */
/* Zilla Slab Font */
@import url('https://fonts.googleapis.com/css2?family=Zilla+Slab:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap');
/* Root */
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6{
  font-family: 'Zilla Slab', serif !important;
}
a{
  text-decoration: none;
  cursor: pointer !important;
}
ul{
  margin: 0 !important;
  padding: 0 !important;
  list-style: none !important;
}
/* Navigationbar */
.css-1t29gy6-MuiToolbar-root{
  padding: 0 !important;
}
.navMenu{
  color: #212934;
  transition: 0.2s;
  font-size: 16px !important;
  letter-spacing: 0 !important;
	padding: 5px 8px !important;
	border-radius: 5px !important;
	margin: 0 5px !important;
	font-weight: 700 !important;
}
.navMenu:hover{
  color: #d23b32 !important;
	/* background-color: #5aa86c !important; */
}
.active{
	color: #21afe7 !important;
}
svg>path{
	fill: #212934 !important;;
}
.title_header {
	position: relative;
	text-align: center;
	color: white;
}

.centered {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.starter_text {
	text-align: start;
}

hr.style-eight {
	overflow: visible;
	/* For IE */
	padding: 0;
	border: none;
	border-top: medium double rgb(194, 17, 17);
	color: #333;
	text-align: center;
	margin: 20px 0;
}

hr.style-eight:after {
	content: "♦";
	display: inline-block;
	position: relative;
	top: -0.7em;
	font-size: 1.5em;
	padding: 0 0.25em;
	background: rgb(255, 255, 255);
}

/* Banner Area */
.slick-prev, .slick-next{
	width: 63px !important;
    height: 63px !important;
    line-height: 63px !important;
		background-color: rgba(0,0,0,.5) !important;
		font-size: 25px !important;
		display: none !important;
		transition: 0.2s;
}
.bannerMainArea:hover .slick-prev, .bannerMainArea:hover .slick-next {
	display: block !important;
}
.slick-next {
	right: 0px !important;
}
.slick-prev {
	left: 0px !important;
	z-index: 9999!important;
}
.slick-next:before {
	content: '>' !important;
	font-family: awb-icons;
}
.slick-prev:before {
	content: '<' !important;
	font-family: awb-icons;
}
.slick-prev:before, .slick-next:before {
	font-family: 'slick';
	font-size: 20px;
	line-height: 1;
	opacity: .75;
	color: #fff !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
/* Admin Panel */
.css-8yl01b-MuiPaper-root-MuiAppBar-root {
	box-shadow: none !important;
}
.css-1t29gy6-MuiToolbar-root {
	min-height: 48px !important;
}

/* Delete & Update Button */
.btnArea{
	margin-top: 40px !important;
	display: flex;
	justify-content: center;
	align-items: center;
}

.updateBtn{
  margin-right: 10px !important;
}
.deleteBtn{
  margin-left: 10px !important;
}

.deleteBtn{
  padding: 13px 29px !important;
  line-height: 17px !important;
  font-size: 14px !important;
  border-radius: 4px !important;
  background-color: #d23b32 !important;
  color: #ffffff !important;
  transition: 0.2s !important;
}
.deleteBtn:hover{
  background: #5aa86c !important;
  color: #ffffff !important;
}
.updateBtn{
  padding: 13px 29px !important;
  line-height: 17px !important;
  font-size: 14px !important;
  border-radius: 4px !important;
  background-color: #5aa86c !important;
  color: #ffffff !important;
  transition: 0.2s !important;
}
.updateBtn:hover{
  background: #d23b32 !important;
  color: #ffffff !important;
}
.css-dzd1b6-MuiTypography-root {
	margin-top: -26px !important;
}
