/* Root */
:root {
  --gray-white: #e7e6e6;
  --cyan-blue: #212934;
  --red: #d23b32;
  --tuna: #4a4e57;
}
/* Title Area */
.title{
  font-size: 40px !important;
  line-height: 1.2 !important;
  color: var(--cyan-blue);
  font-weight: 500 !important;
}
.titleLeft, .titleRight{
  position: relative;
  height: 6px;
  flex-grow: 1;
}
.titleSep {
  position: relative;
  display: block;
  width: 100%;
  border-style: solid;
  border: 0 solid var(--gray-white);
  box-sizing: content-box;
  height: 6px;
  border-bottom-width: 1px;
  border-top-width: 1px;
}
/* Card Area */
.img{
  width: 100%;
  height: 250px;
  position: relative;
  overflow: hidden;
}
.img > img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.overlay{
  background-image: linear-gradient(to top,#65bc7b 0,rgba(101,188,123,0.8) 100%);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: -100%;
  transition: 0.3s;
}
.img:hover .overlay{
  left: 0;
}
.cardTitle{
  font-size: 32px !important;
  line-height: 1.3 !important;
  color: var(--cyan-blue);
  letter-spacing: 0 !important;
  font-weight: 400 !important;
  transition: 0.2s;
}
.cardTitle:hover{
  color: var(--red);
}
.date{
  margin: 10px 0 !important;
  font-size: 13px !important;
  color: var(--tuna);
}
.notice{
  font-size: 13px !important;
  color: var(--tuna);
}
.notice:hover{
  color: var(--red);
}
.shep{
  display: block;
  width: 100%;
  border-style: solid;
  border: 0 solid var(--gray-white);
  box-sizing: content-box;
  height: 6px;
  border-bottom-width: 1px;
  border-top-width: 1px;
}
.description{
  margin: 20px 0 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  color: var(--tuna);
  line-height: 1.8 !important;
}
.link, .icon{
  color: var(--cyan-blue);
  transition: 0.2s;
  font-size: 14px;
}
.icon{
  cursor: pointer;
}
.link:hover, .icon:hover{
  color: var(--red);
}
/* Overlay */
.overlayTitle{
  line-height: 1.36 !important;
  font-weight: 400 !important;
  letter-spacing: 0px !important;
  color: #212934 !important;
  font-size: 28px !important;
  text-align: center !important;
  margin: 10px 0 !important;
}
.overlayIcon{
  width: calc((15px) * 2.41);
    height: calc((15px) * 2.41);
    background-color: #212934;
    line-height: 2.41;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 0 5px;
    color: #FFFFFF !important;
    font-size: 14px;
}