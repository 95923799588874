/* Root */
:root {
  --white: rgb(255, 255, 255);
  --conifer: #a0ce4e;
  --blue: #205189;
  --cyan-blue: #212934;
  --tuna: #4a4e57;
}
/* Main Content */
.title{
  font-size: 40px !important;
  font-weight: 700 !important;
  letter-spacing: 0.00938em !important;
  line-height: 1.5 !important;
  text-transform: uppercase !important;
  color: var(--cyan-blue) !important;
}
.subTitle{
  font-size: 30px !important;
  font-weight: 700 !important;
  letter-spacing: 0.00938em !important;
  line-height: 1.5 !important;
  text-transform: uppercase !important;
  margin: 20px 0 !important;
  color: var(--cyan-blue) !important;
}
.license{
  font-size: 30px !important;
  letter-spacing: 0.00938em !important;
  margin-bottom: 20px !important;
  color: var(--cyan-blue) !important;
}
.details{
  font-size: 20px !important;
  margin-bottom: 14px !important;
  letter-spacing: 0.00938em !important;
  color: var(--tuna) !important;
}
.contactBtn{
  padding: 13px 29px;
  line-height: 17px !important;
  font-size: 16px !important;
  border-radius: 4px;
  background-color: #5aa86c;
  color: var(--white) !important;
  transition: 0.2s;
}
.contactBtn:hover{
  background: #d23b32;
  color: var(--white) !important;
}