/* Root */
:root {
  --gray-white: #e7e6e6;
  --cyan-blue: #212934;
  --red: #d23b32;
  --tuna: #4a4e57;
}
/* Contact Area */
/* Title Area */
.title{
  font-size: 40px !important;
  line-height: 1.2 !important;
  color: var(--cyan-blue);
  font-weight: 500 !important;
}
.titleLeft, .titleRight{
  position: relative;
  height: 6px;
  flex-grow: 1;
}
.titleSep {
  position: relative;
  display: block;
  width: 100%;
  border-style: solid;
  border: 0 solid var(--gray-white);
  box-sizing: content-box;
  height: 6px;
  border-bottom-width: 1px;
  border-top-width: 1px;
}

.pra{
  text-align: left !important;
  color: var(--tuna);
  line-height: 1.8 !important;
  
  font-weight: 400;
  margin: 0 0 20px;
}

/* Form Area */
.contactArea{
  width: 70% !important;
  margin: 0 auto !important;
}
input, textarea, select{
  width: 100%;
  padding: 16.5px 14px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #FFFFFF;
  font-size: 16px;
  color: #9ea0a4;
}
textarea {
  height: 150px !important;
  resize: none !important;
}
.contactUl {
  margin: 10px 0 0;
  padding-left: 30px !important;
}
.contactUl > li {
  font-size: 21px !important;
  line-height: 35.7px !important;
  color: var(--tuna);
  padding: 5.5px 0 !important;
  margin-left: 0 !important;
}
.link{
  color: var(--tuna);
  font-size: 21px !important;
  line-height: 35.7px !important;
  transition: 0.2s;
}
.link:hover{
  color: var(--red);
}

.inputAlign{
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.inputAlign > :first-child{
  margin-right: 8px !important;
}
.inputAlign > :last-child{
  margin-left: 8px !important;
}
.input{
  width: 100% !important;
  margin-bottom: 16px !important;
}
.input:focus{
  border-color: #65bc7b !important;
  outline: #65bc7b !important;
}
.submitBtn{
  padding: 16.5px 14px !important;
  line-height: 17px !important;
  font-size: 16px !important;
  border-radius: 4px !important;
  background-color: #5aa86c !important;
  color: #ffffff !important;
  transition: 0.2s !important;
  display: block !important;
  margin: 0 auto !important;
  width: 100% !important;
}
.submitBtn:hover{
  background: #d23b32 !important;
  color: #ffffff !important;
}
@media only screen and (max-width: 991px){
  .contactArea{
    width: 80% !important;
  }
}
@media only screen and (max-width: 768px){
  .contactArea{
    width: 100% !important;
  }
}